import {get, post} from "./http"

function PaymentArrangementAPI() {
	function initiateSetup({secureCode}) {
		return post(`/app/payment-arrangement/${secureCode}`, {})
	}

	function getConfigureFrequencyPage({paymentArrangementID}) {
		return get(`/app/payment-arrangements/${paymentArrangementID}/configure-frequency`)
	}

	function configureFrequency({paymentArrangementID, frequency}) {
		return post(`/app/payment-arrangements/${paymentArrangementID}/configure-frequency`, {Frequency: frequency})
	}

	function getConfigureStartDatePage({paymentArrangementID}) {
		return get(`/app/payment-arrangements/${paymentArrangementID}/configure-start-date`)
	}

	return {
		initiateSetup,
		getConfigureFrequencyPage,
		configureFrequency,
		getConfigureStartDatePage,
	}
}

export {PaymentArrangementAPI}
